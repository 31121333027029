<style scoped lang="scss">
.fa-comments {
  &.chatalert {
    margin-right: 16px;
  }
}

.va_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  padding: 4px;
  font-size: 14px;
  border-radius: 100%;
  background-color: #00000085;
  cursor: pointer;
  transition: all 0.5s ease;
  outline: none;
  border: none;
  color: #fff;

  .fas {
    font-size: 20px;
  }
}
.alert-chat {
  position: relative;
  top: -10px;
  left: 32px;
  width: 24px;
  padding: 2px;
  font-size: 12px;
  border-radius: 24px;
  border: 2px solid #E23D56;
  background-color: #e23d56dc;
  transition: all 0.5s ease;
}
</style>
<template>
  <button class="va_btn chat" title="Chat" @click="onClickShowChat">
    <div class="alert-chat" v-if="numMsgChat">{{ numMsgChat }}</div>
    <i class="fas fa-comments" :class="{'chatalert': numMsgChat !== null}" />
  </button>
</template>

<script>
export default {
  name: 'ChatButton',

  props:{
    numMsgChat:{
      type:{
        type:Number,
      }
    }
  },
  methods:{
    onClickShowChat(){
      this.$emit('showChat');
    }
  }
};
</script>
