export default {
  data() {
    return {
      hasCameraPermission: false,
      hasMicrophonePermision: false,
      loadingPermissions: true
    };
  },

  computed: {
    hasUserMediaPermission() {
      return [this.hasCameraPermission, this.hasMicrophonePermision].every(item => item === true);
    },
    userHasNotMediaPermission() {
      return !this.hasUserMediaPermission && !this.loadingPermissions;
    },
    isWebView() {
      return window?.ReactNativeWebView;
    }
  },

  methods: {
    async getPermission(permission, key) {
      try {
        if (!this.isWebView) {
          const result = await navigator.permissions.query({ name: permission });
          result.onchange = () => {
            this[key] = result.state === 'granted';
          };
          return result.state === 'granted';
        }
        const video = permission === 'camera';
        const audio = permission === 'microphone';
        const stream = await navigator.mediaDevices.getUserMedia({ video, audio });

        return true;
      } catch (error) {
        return false;
      }
    },

    async processPermissions() {
      try {
        this.loadingPermissions = true;
        const permissionDictionary = {
          hasCameraPermission: 'camera',
          hasMicrophonePermision: 'microphone'
        };

        for (const [key, permission] of Object.entries(permissionDictionary)) {
          this[key] = await this.getPermission(permission, key);
        }
      } catch (error) {

      } finally {
        this.loadingPermissions = false;
      }
    },

    async requestUserMediaPermission() {
      if (!navigator?.mediaDevices?.getUserMedia) {
        return alert('Atenção, navegador não suporta câmera e microfone!');
      }

      if (this.hasUserMediaPermission) {
        return;
      }

      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        stream.getTracks().forEach((track) => track.stop());
        await this.processPermissions();
      } catch (error) {
        return this.$toast.error(
          'A Webcam/Microfone não estão liberados para o uso',
          {
            duration: 3000
          }
        );
      }
    }
  }
};
