<style src="./InfoAlert.scss" scoped lang="scss">
</style>
<template>
  <section class="alert-all">
    <section class="alert-content">
      <div class="l-header">Informação</div>
      <div class="l-text">{{ message }}</div>
      <div class="l-footer">Em breve, esta informação fechará</div>
    </section>
  </section>
</template>

<script>
export default {
  name: 'InfoAlert',
  props: {
    message: {
      type: String,
      required: true
    }
  }
};
</script>
