import moment from 'moment';

export function generateScheduleChannelName({
  patientCpf,
  professionalId,
  scheduleDate,
  scheduleStartTime
}) {
  const momentDate = moment(`${scheduleDate}`, 'DD/MM/YYYY');
  const month = momentDate.month() + 1 < 10 ? `0${momentDate.month() + 1}` : momentDate.month() + 1;
  const momentDateFormat = `${momentDate.date()}${month}${momentDate.year()}`;
  const momentHourFormat = moment(`${scheduleStartTime}`, 'HH:mm').format('HHmm');
  const roomNameAddon = `${momentDateFormat}_${momentHourFormat}`;
  const scheduleChannel = `AG-funId_${professionalId}-pacCpf_${patientCpf}_${roomNameAddon}`;
  return scheduleChannel;
}
