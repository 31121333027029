<style src="./SearchingProfessional.scss" scoped lang="scss"></style>
<template>
  <section class="queue-call">
    <section class="lds-ellipsis" v-if="!profissionalEntered">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </section>
    <div class="__title">
      <span v-if="!qsLinkAgenda || !qsAgendamentoPacienteId">Olá, estamos localizando um profissional <br/>para realizar seu atendimento.</span>
      <span v-else>Aguarde o profissional entrar na sala</span>
    </div>
    <footer class="__subtitle-container">
      <span class="subtitle">{{ label }} {{ subtitle }}</span>
      <span class="subtitle">Por favor, aguarde!</span>
      <div class="col-acts">
        <PlButton class="mt" label="Cancelar ligação" red cross @click="$emit('cancelSearch')" />
      </div>
    </footer>
  </section>
</template>
<script>
import PlButton from '@/components/button/Button.vue';
import SvgImage from './svgImage.vue';

export default {
  name: 'SearchingProfessional',
  components: { SvgImage, PlButton },
  props: {
    profissionalEntered: {
      type: Boolean,
      required: true
    },
    label: {
      type: String,
      required: true,
      default: ''
    },
    subtitle: {
      type: String,
      required: true,
      default: ''
    }
  },
  data() {
    return {
      qsLinkAgenda: null,
      qsAgendamentoPacienteId: null
    };
  },
  mounted() {
    this.qsLinkAgenda = this.$route.query.linkAgenda || null;
    this.qsAgendamentoPacienteId = this.$route.query.agendamento || null;
    console.log('qsLinkAgenda',this.qsLinkAgenda);
    console.log('qsAgendamentoPacienteId',this.qsAgendamentoPacienteId);
  }

};
</script>
