export default class VideoCallMediator {
  _events = {};

  constructor() {
    this._events = {};
  }

  emit(eventName, input = {}) {
    if (!this._events[eventName]) return;
    this._events[eventName].forEach((listener) => {
      listener(input);
    });
  }

  on(eventName, listener) {
    if (!this._events[eventName]) {
      this._events[eventName] = [];
    }
    this._events[eventName].push(listener);
  }
}
