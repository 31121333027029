<style src="./ConnectionIndicator.scss" scoped lang="scss">
</style>
<template>
  <section class="connection">
    <div class="bars">
      <div class="bar one"
           :class="{'off': connectionLevel < 1, 'yellow': connectionColor === 'yellow', 'red': connectionColor === 'red'}"></div>
      <div class="bar two"
           :class="{'off': connectionLevel < 2, 'yellow': connectionColor === 'yellow', 'red': connectionColor === 'red'}"></div>
      <div class="bar three"
           :class="{'off': connectionLevel < 3, 'yellow': connectionColor === 'yellow', 'red': connectionColor === 'red'}"></div>
      <div class="bar four"
           :class="{'off': connectionLevel < 4, 'yellow': connectionColor === 'yellow', 'red': connectionColor === 'red'}"></div>
      <div class="bar five"
           :class="{'off': connectionLevel < 5, 'yellow': connectionColor === 'yellow', 'red': connectionColor === 'red'}"></div>
    </div>
    <div class="conn-text"
         :class="{
            'off': connectionLevel < 1,
            'yellow': connectionColor === 'yellow',
            'red': connectionColor === 'red'}"
    >{{ connectionLabel }}
    </div>
  </section>
</template>

<script>
import moment from 'moment/moment';
import MovementLogs from '@/helpers/movement_logs';
import AgoraService, { AGORA_SERVICE_EVENTS } from '@/packages/@video-call/services/AgoraService';
import FirebaseService from '@/packages/@video-call/services/FirebaseService';

export default {
  name: 'ConnectionIndicator',
  props: {
    AgoraService: {
      required: true,
      type: AgoraService
    }
  },
  data() {
    return {
      connectionColor: '',
      connectionLevel: 1,
      connectionLabel: 'Conexão Estável',
      previousLevel: 1,
      patient: null,

      intervalId: null,
      rttArray: [],
      levelArray: []
    };
  },

  async mounted() {
    this._firebaseService = new FirebaseService();
    this.patient = await this.$store.dispatch('getLocalStoragePatient');
    this.AgoraService.on(AGORA_SERVICE_EVENTS.NETWORK_QUALITY, this.handleTransmissionQuality);
    this.updateConnectionStatus(this.connectionLevel);

    this.intervalId = setInterval(() => {
      this._saveLevelRttArrayToDatabase();
    }, 1 * 60 * 1000); // 1 minuto em milissegundos
  },

  methods: {
    /**
     * Salva o array de RTT no banco de dados e limpa o array local.
    */
    async _saveLevelRttArrayToDatabase() {
      if (this.rttArray.length === 0) return; // Evita salvar se o array estiver vazio
      try {
        const timeAction = moment().format('YYYY-MM-DD HH:mm:ss');
        const payload = {
          pacId: this.patient.pac_id,
          room: this.AgoraService.getChannelName(),
          levelArray: this.levelArray,
          rttArray: this.rttArray,
          timeAction: timeAction
        }
        await MovementLogs.saveLevelRttArray(payload)
        console.log('Level/RTT salvo no banco:', this.rttArray);
        // Limpa o array após salvar
        this.levelArray = [];
        this.rttArray = [];
      } catch (error) {
        console.error('Erro ao salvar Level/RTT no banco:', error);
      }
    },

    handleTransmissionQuality(quality) {
      // console.warn('[PatientVideo] agoraTransmissionQuality', quality);
      const level = quality.level;
      const rtt = quality.sendRttMs || 0;

      this.connectionLevel = level || 0;
      this.updateConnectionStatus(this.connectionLevel);
      this.handleSaveLog(this.connectionLevel, quality);

      this.previousLevel = level || 0;

      this._firebaseService.updateRoomSpecificKey(
        this.AgoraService.getChannelName(),
        'pacienteTransmissionLeval',
        `${level} (Agora RTT: ${rtt}ms)`
      );

      this.levelArray.push(level);
      this.rttArray.push(rtt);
    },

    updateConnectionStatus(level) {
      switch (level) {
        case 1:
        case 2:
          this.connectionColor = 'red';
          this.connectionLabel = 'Conexão Instável';
          break;
        case 3:
          this.connectionColor = 'yellow';
          this.connectionLabel = 'Conexão Estável';
          break;
        case 4:
        case 5:
          this.connectionColor = '';
          this.connectionLabel = 'Conexão Estável';
          break;
        default:
          this.connectionColor = 'red';
          this.connectionLabel = 'Sem Conexão';
          break;
      }
    },

    handleSaveLog(currentLevel, payload) {
      let code = '100';
      switch (currentLevel) {
        case 0:
          code = '100';
          break;
        case 1:
          code = '101';
          break;
        case 2:
          code = '102';
          break;
        case 3:
          code = '103';
          break;
        case 4:
          code = '104';
          break;
        case 5:
          code = '105';
          break;
      }
      const timeAction = moment().format('YYYY-MM-DD HH:mm:ss');
      const payloadLog = {
        pac_id: this.patient.pac_id,
        payload: { ...payload }
      };
      try {
        // Verifica se o nível caiu abaixo de 3 e ainda não foi logado
        if (currentLevel < 3 && !this.wasLoggedBelowThreshold) {
          this.wasLoggedBelowThreshold = true; // Marca que já foi logado
          MovementLogs.saveLog(code, timeAction, payloadLog);
          return;
        }

        // Verifica se o nível subiu para 3 ou acima após estar abaixo de 3
        if (currentLevel >= 3 && this.previousLevel < 3) {
          MovementLogs.saveLog(code, timeAction, payloadLog);
          this.wasLoggedBelowThreshold = false; // Reseta a flag para futuros logs
        }

      } catch (e) {
        console.error(e);
        this.$toast.error('Falha ao salvar log de conexão.');
      }
    }
  }
};
</script>
