var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"pl-btn",class:[
    { primary: _vm.primary },
    { red: _vm.red },
    { blue: _vm.blue },
    { orange: _vm.orange },
    { gray: _vm.gray },
    { ml: _vm.ml },
    { square: _vm.square },
    { rounded: _vm.rounded },
    { actived: _vm.actived },
    { disabled: _vm.disabled },
  ],attrs:{"disabled":_vm.disabled,"title":_vm.title},on:{"click":_vm.onClick}},[(_vm.arrowLeft)?_c('i',{staticClass:"fas fa-chevron-left",class:{ 'no-line': _vm.noLine }}):_vm._e(),(_vm.label && _vm.label.length > 0)?_c('span',[_vm._v(_vm._s(_vm.label))]):_vm._e(),(_vm.cancel)?_c('i',{staticClass:"fas fa-times icon-right",class:{ 'no-line': _vm.noLine }}):_vm._e(),(_vm.check)?_c('i',{staticClass:"fas fa-check icon-right",class:{ 'no-line': _vm.noLine }}):_vm._e(),(_vm.trash)?_c('i',{staticClass:"fas fa-trash icon-right",class:{ 'no-line': _vm.noLine }}):_vm._e(),(_vm.plus)?_c('i',{staticClass:"fas fa-plus icon-right",class:{ 'no-line': _vm.noLine }}):_vm._e(),(_vm.refresh)?_c('i',{staticClass:"fas fa-sync icon-right",class:{ 'no-line': _vm.noLine }}):_vm._e(),(_vm.save)?_c('i',{staticClass:"fas fa-check icon-right",class:{ 'no-line': _vm.noLine }}):_vm._e(),(_vm.history)?_c('i',{staticClass:"fas fa-history icon-right",class:{ 'no-line': _vm.noLine }}):_vm._e(),(_vm.cross)?_c('i',{staticClass:"fas fa-times icon-right",class:{ 'no-line': _vm.noLine }}):_vm._e(),(_vm.search)?_c('i',{staticClass:"fas fa-search icon-right",class:{ 'no-line': _vm.noLine }}):_vm._e(),(_vm.arrowRight)?_c('i',{staticClass:"fas fa-chevron-right icon-right",class:{ 'no-line': _vm.noLine }}):_vm._e(),(_vm.clean)?_c('IconBroom',{staticClass:"icon broom",class:{'no-line': _vm.isSmallOrNoLine, 'icon-right': !_vm.isSmallOrNoLine}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }