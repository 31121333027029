import Firestore from './firestore'
import Database from './database'
import Enum from './enum'

// OBS: Gear está sendo importado no Firestore

console.log('Firestore',Firestore)
export default {
  ...Database,
  ...Firestore,
  ...Enum
}
