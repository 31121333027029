<style src="./Loading.scss" lang="scss"></style>

<template lang="html">
  <section class="loading-all">
    <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
  </section>
</template>

<script>

export default {
  name: 'Loading',
  props: {
  },

  data () {
    return {
    }
  },

  computed: {},
  watch: {},

  methods: {
  }
}
</script>
