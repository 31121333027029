<style src="./ExistentRoomModal.scss" scoped lang="scss">
</style>
<template>
  <section class="back-modal" v-if="showModalExistRoom">
    <div class="modal-room">
      <section v-if="!showJustification" class="col-left">
        <span class="title">Você possui um atendimento em andamento.</span>
        <span class="subtitle">O seu último atendimento não foi finalizado</span>
        <span class="subtitle">Gostaria de voltar para a sala com {{ objExistRoom ? objExistRoom.funNome : 'Indefinido'
          }} ou solicitar um novo atendimento?
        </span>
        <footer class="act">
          <button class="btn white" @click="toggleJustification">
            Fechar <i class="fas fa-plus"></i>
          </button>
          <button class="btn" @click="gotoVideo">
            Voltar para a Sala<i class="fas fa-chevron-right"></i>
          </button>
        </footer>
      </section>
      <section v-else class="col-left">
        <span class="title">Você possui um atendimento em andamento.</span>
        <span class="subtitle">Digite a justificativa para desistir do atendimento</span>
        <TextInput label="Justificativa" :maxlength="70" required v-model="quitJustification" />
        <footer class="act">
          <button class="btn white" @click="toggleJustification">
            Voltar <i class="fas fa-chevron-left"></i>
          </button>
          <button class="btn" @click="handleQuit">
            Desistir<i class="fas fa-check"></i>
          </button>
        </footer>
      </section>
      <div class="col-right">
        <SvgImage />
      </div>
    </div>
  </section>
</template>

<script>
import SvgImage from '@/views/paciente/home/svgImage.vue';
import Firebase from '@/helpers/firebase/index';
import moment from 'moment/moment';
import TextInput from '@/components/inputs/text-input/TextInput.vue';
import { ServicoHttp } from '@/axios/servico-http';
import { ServicoAll } from '@/axios/servico-all';
import FirebaseService from '@/packages/@video-call/services/FirebaseService';

export default {
  name: 'ExistentRoomModal',
  components: { TextInput, SvgImage },
  data() {
    return {
      showModalExistRoom: false,
      showJustification: false,
      objExistRoom: {},
      quitJustification: ''
    };
  },
  beforeMount() {
    this._firebaseService = new FirebaseService()
  },
  async mounted() {
    this.servicoHttp = new ServicoHttp(this);
    this.sPepSaveWithdrawalJustify = new ServicoAll(
      '/api/pep/atendimento/save-withdrawal-justify',
      this.servicoHttp
    );
    this.patient = await this.$store.dispatch('getLocalStoragePatient');
    const isThereStreamIdPaciente = await this._firebaseService.isThereStreamIdPacienteByPatientId(this.patient.pac_id);
    if (isThereStreamIdPaciente.exists) {
      if (isThereStreamIdPaciente.docRoom.bloqueado === 1) {
        return;
      }
      this.showModalExistRoom = true;
      this.objExistRoom = isThereStreamIdPaciente.docRoom;
    }
  },
  methods: {
    async toggleJustification() {
      this.showJustification = !this.showJustification;
    },
    async handleQuit() {
      if (!this.quitJustification) {
        return this.$toast.error('A Justificativa é obrigatória.');
      }
      if (this.quitJustification.length < 3) {
        return this.$toast.error('A Justificativa deve ter 3 ou mais caracteres!');
      }
      this.$emit('loading', true);
      try {
        const atdId = this.objExistRoom.atdId;
        await this.sPepSaveWithdrawalJustify.post({
          atdId,
          justifyWithdrawal: this.quitJustification
        });
        const channel = this.objExistRoom.canal;
        const key = `bloqueado`;
        const value = 1;
        await Firebase.updateRoomSpecificKey(channel, 'patientCancel', true);
        await Firebase.updateRoomSpecificKey(channel, key, value);
        this.showModalExistRoom = false;
      } catch (error) {
        this.$toast.error('Falha ao desistir do atendimento.');
        console.error('handleQuit', error.message);
      } finally {
        this.$emit('loading', false);
      }
    },
    async gotoVideo() {
      this.$emit('loading', true);

      // await Firebase.updateRoomSpecificKey(this.objExistRoom.canal, 'streamIdPaciente', null);
      // await Firebase.updateRoomSpecificKey(this.objExistRoom.canal, 'streamIdProfissional', null);

      const momNow = moment();
      const momBirth = moment(this.patient.usu_nascimento, 'YYYY-MM-DD');
      const age = momNow.diff(momBirth, 'years');
      const funId = this.objExistRoom.funId;
      await Firebase.updateProfessionalSpecificKey(funId, 'status', 'ligação');
      await Firebase.updateProfessionalSpecificKey(funId, 'pac_id', this.patient.pac_id);
      await Firebase.updateProfessionalSpecificKey(funId, 'pac_idade', age);
      await Firebase.updateProfessionalSpecificKey(funId, 'pac_imagem', this.patient.usu_imagem_url);
      await Firebase.updateProfessionalSpecificKey(funId, 'pac_nome', this.patient.pac_nome);
      await Firebase.updateProfessionalSpecificKey(funId, 'usu_cpf', this.objExistRoom.pacCpf);

      const linkAgora = this.objExistRoom.canal;
      this.$emit('loading', false);

      window.location.href = `${process.env.VUE_APP_URL_PORTAL_PACIENTE}?linkAgenda=${linkAgora}`;
    }
  }
};
</script>
