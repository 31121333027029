import CustomSessionStorage from '@/services/CustomSessionStorage';

class AttendancePreferences {

  constructor(storageSystem) {
    this._storageSystem = storageSystem;
  }

  savePreferences({ audioEnable, videoEnable }) {
    this._storageSystem.save('preferences', { audioEnable, videoEnable });
  }

  getPreferences() {
    const preferences = this._storageSystem.get('preferences');
    return preferences;
  }
  cleanPreferences() {
    this._storageSystem.remove('preferences');
  }
}



export default new AttendancePreferences(CustomSessionStorage);
