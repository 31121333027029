import Store from '@/store'

const getHeaderAuth = async (headers = {}) => {
  const patient = await Store.dispatch('getLocalStoragePatient')
  headers.authorization = patient.token || null
  headers['Content-Type'] = headers['Content-Type'] || 'application/json'
  headers['Request-Timeout'] = '240000'

  return headers
}

export { getHeaderAuth }
