<style src="./PatientVideo.scss" lang="scss" scoped></style>
<template>
  <section class="patient-video">
    <PatientHeader no-actions />
    <main class="content">
      <SearchingProfessional
        v-show="!showVideo"
        :subtitle="sublabelCC||''"
        :label="labelCC||''"
        :profissional-entered="profissionalEntered"
        @cancelSearch="actShowModalCancelCall"
      />
      <section class="on-call-container" v-show="showVideo">
        <section class="two-columns">
          <div class="video-area">
            <NoPermissionAlert/>
            <RemoteStreamMediaStatus :agora-service="agoraService" />
            <ConnectionIndicator :agora-service="agoraService" />
            <RemoteVideo />
            <LocalVideo />
            <VideoActions :agora-service="agoraService" @cancelCall="actShowModalCancelCall" >
              <ChatButton class="chat-button"  :num-msg-chat="numMsgChat" @showChat="onClickShowChat" />
            </VideoActions>
          </div>
          <div class="chat-content">
            <Chat :objFun="objFun" v-if="profissionalEntered" />
          </div>
        </section>
      </section>
    </main>
    <ChatModal :is-open="showChat" @closeChat="onClickHideChat">
      <Chat :objFun="objFun" @onMessage="onChatMessage" />
    </ChatModal>
    <InfoAlert v-if="showAlert" :message="alertTxt" />
    <CallTimeout v-if="showGatewayTimeoutMessage" @cancelCall="actShowModalCancelCall" @tryAgain="startCallingCircle" />
    <Delay v-if="showServiceDelayAlert" @handelCancel="actShowModalCancelCall"
           @handelConfirm="onClickContinueDelayAlert" />
    <CancelCall v-if="showModalCancelCall" @handelCancel="hideCancelModal" @handelConfirm="preActCancelCall" />
  </section>
</template>

<script>

import PatientHeader from '../components/header/PatientHeader';
import Chat from '@/packages/@video-call/components/chat/Chat';
import PlButton from '@/components/button/Button';
import SearchingProfessional from '@/packages/@video-call/components/searching-professional/SearchingProfessional.vue';
import ConnectionIndicator from '@/packages/@video-call/components/connection-indicator/ConnectionIndicator.vue';
import VideoActions from '@/packages/@video-call/components/video-actions/VideoActions.vue';
import InfoAlert from '@/packages/@video-call/components/modals/alert/InfoAlert.vue';
import CallTimeout from '@/packages/@video-call/components/modals/timeout/CallTimeout.vue';
import Delay from '@/packages/@video-call/components/modals/delay/Delay.vue';
import CancelCall from '@/packages/@video-call/components/modals/cancel-call/CancelCall.vue';
import LocalVideo from '@/packages/@video-call/components/local-video/LocalVideo.vue';
import RemoteVideo from '@/packages/@video-call/components/remote-video/RemoteVideo.vue';
import VideoCallMixin from '@/packages/@video-call/mixins/VideoCallMixin';

import MediaDeviceMixin from '@/mixin/MediaDeviceMixin';
import RemoteStreamMediaStatus
  from '@/packages/@video-call/components/remote-stream-media-status/RemoteStreamMediaStatus.vue';
import ChatModal from '@/packages/@video-call/components/chat-modal/ChatModal.vue';
import ChatButton from '@/packages/@video-call/components/chat-button/ChatButton.vue';
import ChatMixin from '@/packages/@video-call/mixins/ChatMixin';
import NoPermissionAlert from '@/packages/@video-call/components/no-permissions-alert/NoPermissionAlert.vue';

export default {
  name: 'PortalPatientVideo',
  mixins: [VideoCallMixin, MediaDeviceMixin, ChatMixin],
  components: {
    NoPermissionAlert,
    ChatButton,
    ChatModal,
    RemoteStreamMediaStatus,
    RemoteVideo,
    LocalVideo,
    CancelCall,
    Delay,
    CallTimeout,
    InfoAlert,
    VideoActions,
    ConnectionIndicator,
    SearchingProfessional,
    PlButton,
    PatientHeader,
    Chat,
  },

  data() {
    return {
      isMobile: false,
    };
  },
  methods: {
    preActCancelCall () {
      this.showModalCancelCall = false;
      this.actCancelCall()
    },
    hideCancelModal() {
      this.showModalCancelCall = false;
    },
    consoleWarn(...args) {
      console.warn(...args);
    },
    consoleLog(...args) {
      console.log(...args);
    },
    consoleInfo(...args) {
      console.info(...args);
    },
    consoleError(...args) {
      console.error(...args);
    },
    getNpsRoute() {
      return 'pacienteNps';
    },

  }
};
</script>
