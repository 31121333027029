class CustomSessionStorage {
  constructor() {
  }

  save(key, value) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  get(key) {
    const output = JSON.parse(sessionStorage.getItem(key));
    return output;
  }

  remove(key){
    sessionStorage.removeItem(key);
  }
}

export default new CustomSessionStorage();

export const STORAGE_KEYS = {
  REFRESH_CALL:'@A4PM-refreshCall'
}
