import { getErrorMessage } from '@/helpers/utils/getErrorMessage';
import packageInfo from '../../package.json'

export default {
  data() {
    return {
      client: process.env.VUE_APP_THEME.toLowerCase()
    }
  },

  computed: {
    baseColor() {
      const system = String(process.env.VUE_APP_THEME).toLocaleUpperCase()
      if (system === 'AXIA') {
        return '#DC7700'
      }
      else if (system === 'AMA') {
        return '#003986'
      }
      else if (system === 'DURGA') {
        return '#333333'
      }
      else if (system === 'EMERCOR') {
        return '#EF4035'
      }
      else if (system === 'MEDICAR') {
        return '#730707'
      } else {
        return '#36474F'
      }
    },
    appVersion() {
      return packageInfo.version;
    },
    isDevelopmEnviroment () {
      return process.env.VUE_APP_ENVIROMENT === 'develop'
    },
    appSystemFrom () {
      if (this.$route.query?.from) {
        return this.$route.query.from.toLocaleUpperCase()
      }

      return String(process.env.VUE_APP_SYSTEM_FROM_PA).toLocaleUpperCase()
    },
    appSystemTo () {
      return String(process.env.VUE_APP_SYSTEM_TO_PA).toLocaleUpperCase()
    },
    appLogo() {
      switch (this.appSystemFrom) {
        case 'AXIA':
          return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/icone-axia_1.png?alt=media&token=b63e6c51-b38e-4519-950a-ec45b9ebaef9'
        case 'HOMEANGELS':
          return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/home-angels.png?alt=media&token=071a4ff0-7d57-4f0d-b8b1-0d10d063f76c'

        default:
          return process.env.VUE_APP_IMG_LOGO
      }
    },
    appLogoChat() {
      switch (this.appSystemFrom) {
        case 'AXIA':
          return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/icone-axia_1.png?alt=media&token=b63e6c51-b38e-4519-950a-ec45b9ebaef9'
        case 'HOMEANGELS':
          return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/home-angels.png?alt=media&token=071a4ff0-7d57-4f0d-b8b1-0d10d063f76c'
        case 'MAXPLANET':
          return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/maxplanet.svg?alt=media&token=e943bfd1-6e86-4438-9f62-e21d7afc3c81'

        default:
          return process.env.VUE_APP_IMG_LOGO
      }
    },
    appLogoWhite() {
      if (this.client === "axia" || this.$route.query.from === "AXIA") {
        return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/icone-axia_1.png?alt=media&token=b63e6c51-b38e-4519-950a-ec45b9ebaef9'
      }

      return process.env.VUE_APP_IMG_LOGO_BRANCA
    },
    appDeeplink() {
      if (this.$route.query.deeplink) {
        return this.$route.query.deeplink.toLocaleLowerCase()
      }

      return this.appSystemFrom !== this.appSystemTo ? process.env.VUE_APP_AXIA_DEEPLINK : process.env.VUE_APP_IOS_DEEPLINK
    },
    giftCardSvg () {
      if (this.client === "maxplanet") {
        return require('@/assets/gift_card_3.svg')
      }
      if (this.client === "medgold") {
        return require('@/assets/gift_card_medgold.svg')
      }
      if (this.client === "ucardz") {
        return require('@/assets/gift_card_ucardz.svg')
      }
      if (this.client === "maissaude") {
        return require('@/assets/gift_card_maissaude.svg')
      }
      if (this.client === "axia") {
        return require('@/assets/gift_card_axia.svg')
      }
      if (this.client === "associado") {
        return require('@/assets/gift_card_associado.svg')
      }
      if (this.client === "soumais") {
        return require('@/assets/gift_card_soumais.svg')
      }
      if (this.client === "salute") {
        return require('@/assets/gift_card_salute.svg')
      }
      if (this.client === "cardfacil") {
        return require('@/assets/gift_card_cardfacil.svg')
      }
      if (this.client === "clubepax") {
        return require('@/assets/gift_card_clubepax.svg')
      }
      if (this.client === "memoriau") {
        return require('@/assets/gift_card_memoriau.svg')
      }
      if (this.client === "ativacao") {
        return require('@/assets/gift_card_ativacao.svg')
      }

      return require('@/assets/gift_card_2.svg')
    },
    svgColor () {
      const medicarColor = '#730707'

      return `var(--supportColor, var(--svgColor,var(--mainColor, ${medicarColor})))`
    },
    privacyForm() {
      if (this.client === "maxplanet") {
        return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/priv-maxplanet.pdf?alt=media&token=cc81fa1b-4802-4af7-9fb4-d90d51e4ef9b#toolbar=0'
      }

      if (this.client === 'medgold') {
        return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/Politica_de_Privacidade_MEDGOLD_23_07_24.pdf?alt=media&token=19028df6-4039-4ee9-a413-72d6d9d0fe42'
      }

      if (this.client === 'ucardz') {
        return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/Politica_de_Privacidade_uCarz_04092024.pdf?alt=media&token=055216c6-adac-42c1-be14-54f2765141fa'
      }

      if (this.client === 'acessa') {
        return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/Politica_de_Privacidade_acessa.pdf?alt=media&token=76c8e394-5f06-4453-9575-73353f846998'
      }

      if (this.client === 'maissaude') {
        return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/Politica_de_Privacidade_maissaude.pdf?alt=media&token=85c114a4-5349-4d5d-93d7-f5e1a61bd65b'
      }

      if (this.client === 'associado') {
        return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/politica_de_privacidade_associados.pdf?alt=media&token=111b6932-7cb4-486a-918c-4e89266e5835'
      }

      if (this.client === 'soumais') {
        return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/Politica_de_Privacidade_Sou_Mais_Brasil.pdf?alt=media&token=082f3f9f-361b-4d22-a434-81d6748ff462'
      }
      if (this.client === 'salute') {
        return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/Politica_de_Privacidade_Salute_Atualizado_30_outubro.pdf?alt=media&token=188f81fd-d123-45fb-9da9-7490608126c7'
      }

      if (this.client === 'cardfacil') {
        return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/Politica_de_Privacidade_CardFa%CC%81cil_v1.pdf?alt=media&token=802eb583-ea0f-4711-8e27-a767771f798a'
      }

      if (this.client === 'clubepax') {
        return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/Politica_de_Privacidade_Aser_v2.pdf?alt=media&token=37170e32-e57d-4fb8-b834-c61445ed885e'
      }

      if (this.client === 'memoriau') {
        return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/memoriauPoliticaPrivacidadePdf.pdf?alt=media&token=bfb57bfa-0c3a-4f94-a5bc-cf0b01c5700e'
      }

      if (this.client === 'ativacao') {
        return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/Politica_de_Privacidade_Ativacao.pdf?alt=media&token=84c1e7f8-befa-4c98-b029-409fd50c1235'
      }

      return null
    },
    consentForm() {
      if (this.client === "maxplanet") {
        return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/terms-maxplanet.pdf?alt=media&token=f4d92cac-9af5-40fe-95b4-5a98ff1115c6#toolbar=0'
      }

      if (this.client === 'medgold') {
        return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/Termos%20de%20uso_MEDGOLD_23_07_24.pdf?alt=media&token=47667713-426a-47ff-b5bd-8968ebab5e0d'
      }

      if (this.client === 'ucardz') {
        return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/Termos_de_uso_uCardz_04092024.pdf?alt=media&token=059d3e2c-d5cd-4b2c-b488-fd68bfd63f83'
      }

      if (this.client === 'acessa') {
        return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/Termos_de_uso_acessa.pdf?alt=media&token=7082f1e4-3a7e-469c-a8ba-afbe97bfb45e'
      }

      if (this.client === 'maissaude') {
        return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/Termos_de_uso_maissaude.pdf?alt=media&token=af0f9e7e-7f5b-4271-9703-734f8d79d26d'
      }

      if (this.client === 'associado') {
        return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/termos_de_uso_associados.pdf?alt=media&token=db62e67b-3341-4969-848e-fe8843a518b0'
      }

      if (this.client === 'soumais') {
        return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/Termos%20de%20uso_Sou_Mais_Brasil.pdf?alt=media&token=18db1912-5f77-4588-9814-a21c9b035eac'
      }

      if (this.client === 'salute') {
        return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/Termos_de_uso_Salute_Atualizado_30_outubro.pdf?alt=media&token=4e1d1c6d-1ee2-45f9-a310-81dde8f9bbc4'
      }

      if (this.client === 'cardfacil') {
        return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/Termos%20de%20uso_CardFa%CC%81cil_v1.pdf?alt=media&token=7a8ae5ee-e156-4cb3-afdd-dba6b88ba38e'
      }

      if (this.client === 'clubepax') {
        return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/Termos%20de%20uso_Aser_v1%5B1%5D.pdf?alt=media&token=cd69bef5-cb41-40c1-97c1-9278f51af8fd'
      }
      
      if (this.client === 'memoriau') {
        return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/memoriauTermoPdf.pdf?alt=media&token=2db08448-10d5-4ab3-8296-9de860a67d5c'
      }

      if (this.client === 'ativacao') {
        return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/Termos_de_uso_Ativacao.pdf?alt=media&token=6887beca-7ce8-4994-8e13-2765d45158fc'
      }


      return null
    },
    termComponentName() { // return the component name
      if(["maxplanet", "medgold", "maissaude", "acessa", "ucardz",'associado', 'soumais', 'salute', 'cardfacil', 'clubepax', 'memoriau', 'ativacao'].includes(this.client)) {
        return "ConsentTermIframe"
      } else if (this.client === "axia") {
        return "TermAxia"
      } else {
        return "TermMedicar";
      }
    },
  },
  methods: {
    getErrorMessage
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler(value) {
        if (value?.from) {
          document.getElementById("root").className = this.appSystemFrom.toLocaleLowerCase()

          if (value.from.toUpperCase() === 'AXIA') {
            const favicon = document.getElementById("favicon");
            favicon.setAttribute("href", `${process.env.VUE_APP_AXIA_PACIENTE}/favicon-axia.png`);
          }
        }
      }
    }
  }
};
