import Firebase from '@/helpers/firebase/index';
import AgoraManager from '@/packages/@video-call/services/agora_manager/agora_manager';
import VideoCallMediator from '@/packages/@video-call/services/VideoCallMediator';

export class VideoCallError extends Error {
  constructor(message) {
    super(message);
    this.name = 'VideoCallError';
  }
}

export const AGORA_SERVICE_EVENTS = {
  USER_JOINED: 'user-joined',
  USER_LEFT: 'user-left',
  USER_PUBLISHED: 'user-published',
  USER_UNPUBLISHED: 'user-unpublished',
  INFO_UPDATE: 'user-info-updated',
  NETWORK_QUALITY: 'network-quality'
};
export const AGORA_SERVICE_CUSTOM_EVENTS = {
  UNAUTHORIZED_PEAR: 'unauthorized_pear',
  FINISHED_ENTER_ROOM: 'finish_enter_room'
};


class AgoraService {
  _videoOn = true;
  _audioOn = true;
  _blurOn = true;

  constructor() {
    console.log('CONSTRUCT AgoraService');
    this._videoCallMediator = new VideoCallMediator();
    this._agoraManager = null;
    this._remotePlayerContainer = null;
    this._localPlayerContainer = null;
    this._channelParameters = {
      localAudioTrack: null,
      localVideoTrack: null,
      remoteAudioTrack: null,
      remoteVideoTrack: null,
      remoteUid: null
    };
    this._joinParameters = {
      channelName: null,
      token: null,
      pacCpf: null,
      uid: null
    };
    this._objRoomAgora = null;
    this._initialize();
  }

  _initialize() {
    this._remotePlayerContainer = document.createElement('div');
    this._localPlayerContainer = document.createElement('div');
  }

  async enterRoom(payload, objPatient) {
    try {
      this._objRoomAgora = {
        room: payload.canal,
        token: payload.token,
        uid: payload.canal
      };

      this._joinParameters = {
        channelName: payload.canal,
        token: payload.token,
        pacCpf: objPatient.usu_cpf,
        uid: this._generateUid(objPatient.usu_cpf)
      };
      await this.leaveRoom();
      const localStreamDiv = document.getElementById('local_stream');
      this._remotePlayerContainer = document.createElement('div');
      this._localPlayerContainer = document.createElement('div');
      this._agoraManager = await AgoraManager(this._handleEvents.bind(this));
      await this._agoraManager.join(localStreamDiv, this._localPlayerContainer, this._channelParameters, this._joinParameters);
      this._videoCallMediator.emit(AGORA_SERVICE_CUSTOM_EVENTS.FINISHED_ENTER_ROOM, this._objRoomAgora);
      await Firebase.updateStreamIdPacienteByRoom(payload.canal, this._joinParameters.uid);
    } catch (error) {
      throw new VideoCallError('Falha ao entrar na sala');
    }
  }

  _generateUid(pacCpf) {
    return `pacCpf_${pacCpf}`;
  }

  _handleEvents = (eventName, ...args) => {
    if (eventName === AGORA_SERVICE_EVENTS.USER_PUBLISHED) {
      this._onRemoteUserPublished(args);
    }
    if (eventName === AGORA_SERVICE_EVENTS.USER_UNPUBLISHED) {
      this._onRemoteUserUnpublished(args);
    }
    if (eventName === AGORA_SERVICE_EVENTS.NETWORK_QUALITY) {
      const formattedNetworkQuality = this._onNetworkQuality(args[0]);
      this._videoCallMediator.emit(eventName, formattedNetworkQuality);
      return;
    }
    this._videoCallMediator.emit(eventName, args);
  };

  _onRemoteUserPublished(args) {
    const remoteUid = args[0].uid.toString();
    if(remoteUid.match('pacCpf')){
      return null;
    }
    console.warn('remoteUid', remoteUid);
    if (!this._objRoomAgora.room.includes(remoteUid)) {
      this._videoCallMediator.emit(AGORA_SERVICE_CUSTOM_EVENTS.UNAUTHORIZED_PEAR, {});
      const numberPattern = /\d+/;
      const result = remoteUid.match(numberPattern);
      const funId = result[0];
      console.warn('funId', funId);
      Firebase.updateProfessionalSpecificKey(funId, 'comando', 'sairDaSala');
      return;
    }
    if (args[1] == 'video') {
      this._channelParameters.remoteVideoTrack = args[0].videoTrack;
      this._channelParameters.remoteAudioTrack = args[0].audioTrack;
      this._channelParameters.remoteUid = remoteUid;
      // ! coloca o video remoto (profissional)
      console.warn('USER PUBLISHED', args[0]);
      this._remotePlayerContainer.id = remoteUid;
      this._remotePlayerContainer.style.minWidth = '60vw';
      // Append the remote container to the page body.
      document.getElementById('expand_stream').appendChild(this._remotePlayerContainer);
      // Play the remote video track.
      this._channelParameters.remoteVideoTrack.play(this._remotePlayerContainer);
      this.profissionalEntered = true;
      return;
    }
    // Subscribe and play the remote audio track If the remote user publishes the audio track only.
    // Get the RemoteAudioTrack object in the AgoraRTCRemoteUser object.
    this._channelParameters.remoteAudioTrack = args[0].audioTrack;
    // Play the remote audio track. No need to pass any DOM element.
    this._channelParameters.remoteAudioTrack.play();
  }

  _onRemoteUserUnpublished(args) {
    console.warn('user-unpublished');
  }

  _onNetworkQuality = (quality) => {
    let level = this._calculateConnectionQuality(quality);
    const agoraRtt = quality.sendRttMs;
    let navigatorRtt = 0;
    if (navigator.connection) {
      navigatorRtt = navigator.connection.rtt;
      // navigatorType = navigator.connection.effectiveType;
      if (navigatorRtt === 0 && agoraRtt > 0) {
        // level = 1;
      } else if (navigatorRtt === 0) {
        // level = 0;
      } else if (navigatorRtt >= 600) {
        // level = 1;
      }
    }
    quality.level = level;
    return quality;
  };

  /**
   * Calcula o nível de qualidade da conexão com base em vários parâmetros.
   * @private
   * @param {Object} params - Os parâmetros da conexão.
   * @returns {number} - O nível de qualidade da conexão (de 1 a 5).
   */
  _calculateConnectionQuality({ downlinkNetworkQuality, uplinkNetworkQuality }) {
    const qualityAverage = Math.ceil(
      (downlinkNetworkQuality + uplinkNetworkQuality) / 2
    );
    switch (qualityAverage) {
      case 1:
        return 5;
      case 2:
        return 4;
      case 3:
        return 3;
      case 4:
        return 2;
      case 5:
        return 1;
      default:
        return 0;
    }
  }

  async leaveRoom() {
    console.warn('LEAVE ROOM', this._channelParameters.localVideoTrack);
    if (!this._channelParameters.localVideoTrack) {
      console.warn('Sem localVideoTrack');
      return;
    }
    await this._agoraManager.leave(this._channelParameters);
    this._removeView(`local_video`);

    if (this._remotePlayerContainer?.id) {
      this._removeView(this._remotePlayerContainer.id);
    }
  }

  async toggleVideo() {
    const newVideoStatus = !this._videoOn;
    await this._channelParameters.localVideoTrack.setMuted(!newVideoStatus);
    this._videoOn = newVideoStatus;
    return this._videoOn;
  }

  async toggleAudio() {
    const newAudioStatus = !this._audioOn;
    await this._channelParameters.localAudioTrack.setMuted(!newAudioStatus);
    this._audioOn = newAudioStatus;
    return this._audioOn;
  }

  async blurOnOff(callback) {
    if (this._blurOn) {
      this._blurOn = false;
      await this._agoraManager.disableBackground(this._channelParameters);
    } else {
      this._blurOn = true;
      await this._agoraManager.setBackgroundColor(this._channelParameters);
    }
    const obj = {
      status: this._blurOn
    };
    callback(obj);
  }

  getChannelName() {
    if (!this._objRoomAgora) {
      console.warn('[Agora Manager] getChannelName() no channel',this._objRoomAgora);
      return '';
    }
    return this._objRoomAgora.room;
  }


  _removeView(id) {
    console.warn('Removing ' + id + 'Div');
    const div = document.getElementById(id);
    if (div) {
      div.remove();
    }
  }

  on(eventName, callback) {
    this._videoCallMediator.on(eventName, callback);
  }

}

export default AgoraService;
