<style scoped lang="scss">
@import "@/styles/colors";

.video-screen {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  background-color: $darkGray;
  box-shadow: 5px 5px 20px #86868640;
  transition: all 0.5s ease;
  object-fit: cover;

  .expand_stream {
    display: flex;
    flex: 1;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    transition: all 0.5s ease;

  }
    &:deep(div) {
      border-radius: 0.5rem;
      object-fit: cover;
      display: flex;
      flex: 1;
      width: 100%;
      height: 100%;
    }
    &:deep(video){
      display: flex;
      flex: 1;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
}
</style>
<template>
  <section class="video-screen">
    <div class="expand_stream" id="expand_stream"></div>
  </section>
</template>
<script>
export default {
  name: 'RemoteVideo'
};
</script>
