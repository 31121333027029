<style scoped lang="scss">
.permissions-check__not_allowed {
  border: 1px solid #DB3768;
  background: #FCF8E3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  margin: 0 auto;
  border-radius: 0.3rem;
  gap: 1rem;
  //min-width: 83rem;
  @include respond-to('md') {
    flex-direction: row;
    gap:2rem;
    padding: 1rem;
  }

  & > div {
    display: flex;
    flex-direction: column;
    flex: 1;
    text-align: left;

    h5 {
      font-family: Roboto, sans-serif;
      font-size: 1.1rem;
      margin: 0;
      font-weight: 700;
      line-height: 28.13px;
      text-align: left;
      color: #DB3768;
    }

    span {
      font-family: Roboto, sans-serif;
      font-size: 1.1rem;
      font-weight: 400;
      line-height: 28.13px;
      text-align: left;
      color: #666666;
    }
  }
  &>button{
    margin-left: auto;
  }
}

</style>
<template>
  <section class="permissions-check__not_allowed">
    <div>
      <h5>Permissões Pendentes</h5>
      <span>As permissões de voz e vídeos não foram habilitadas. Para iniciar a chamada, será necessário ajustar as suas permissões no botão a seguir.</span>
    </div>
    <Button class="ask-permissions-button" primary label="Liberar Permissões" @click="$emit('askPermissions')"></Button>
  </section>
</template>

<script>
import Button from '@/components/button/Button.vue';

export default {
  name: 'NoPermissionsAlert' ,
  components: { Button }
};
</script>
