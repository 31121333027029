<style src="./BlurButton.scss" lang="scss"></style>

<template lang="html">
  <button class="blur_button__container"
    :title="title"
    @click="onClick"
  >
    <div class="blur_button__background" :class="{ square }"/>
    <div class="blur_button__content">
        <slot/>
    </div>
  </button>
</template>

<script>

export default {
  name: 'BlurButton',
  components: {
  },
  props: {
    title: String,
    customClass: String,
    square: {
        type: Boolean,
        default: false
    }
  },
  data () {
    return {
    }
  },
  watch: {},
  methods: {
    onClick () {
      this.$emit('click')
    }
  }
}
</script>
