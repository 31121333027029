<style src="../BaseModal.scss" scoped lang="scss">
</style>
<template>
  <section class="base-modal">
    <section class="base-modal__content">
      <header class="base-modal__header">Conexão Instável</header>
      <section class="base-modal__body">
        <p>Sua conexão apresenta instabilidade.</p>
        <p>Sugerimos tentar novamente com uma conexão mais estável</p>
      </section>
      <footer class="base-modal__footer">
        <PlButton class="mt" label="Cancelar" red @click="$emit('cancelCall')" />
        <PlButton class="mt" label="Tentar Novamente" blue @click="$emit('tryAgain')" />
      </footer>
    </section>
  </section>
</template>

<script>
import PlButton from '@/components/button/Button.vue';

export default {
  name: 'CallTimeout' ,
  components: { PlButton }
};
</script>
