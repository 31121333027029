<style scoped lang="scss">
.chat__modal {
  z-index: 999999;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100vw;
  height: 100vh;
  padding: 1rem;
  background-color: #000000b5;
  transition: all 0.5s ease;
  .chat-close {
    margin-bottom: 1rem;
    font-size: 2.4rem;
    color: #E23D56;
    transition: all 0.5s ease;
  }
}
</style>

<template>
  <section class="chat__modal" v-show="isOpen">
    <div class="chat-close" @click="onClickHideChat">
      <i class="far fa-times-circle"></i>
    </div>
    <slot/>
  </section>
</template>


<script>
import Chat from '@/packages/@video-call/components/chat/Chat.vue';

export default {
  name: 'ChatModal',
  components: { Chat },
  props: {
    isOpen:{
      type: Boolean,
      required: true,
    }
  },
  methods: {
    onClickHideChat(){
      this.$emit('closeChat');
    }
  }
};
</script>
