<style src="./MobileVideoCall.scss" lang="scss" scoped></style>
<template>
  <section class="patient-video">
    <main class="content">
      <audio id="audio-player-msg" muted>
        <source src="@/assets/audio/msg.mp3" type="audio/mp3">
      </audio>
      <SearchingProfessional
        v-show="!showVideo"
        :subtitle="sublabelCC||''"
        :label="labelCC||''"
        :profissional-entered="profissionalEntered"
        @cancelSearch="actShowModalCancelCall"
      />
      <section class="on-call-container" v-show="showVideo">
        <section class="two-columns">
          <div class="video-area">
            <NoPermissionAlert/>
            <RemoteStreamMediaStatus :agora-service="agoraService" />
            <ConnectionIndicator :agora-service="agoraService"/>
            <RemoteVideo />
            <LocalVideo />
            <VideoActions :agora-service="agoraService"  @cancelCall="actShowModalCancelCall">
              <ChatButton :num-msg-chat="numMsgChat" @showChat="onClickShowChat" />
            </VideoActions>
          </div>
        </section>
      </section>
    </main>
    <ChatModal :is-open="showChat" @closeChat="onClickHideChat">
      <Chat :objFun="objFun" @onMessage="onChatMessage" />
    </ChatModal>
    <InfoAlert v-if="showAlert" :message="alertTxt" />
    <CallTimeout v-if="showGatewayTimeoutMessage" @cancelCall="actShowModalCancelCall" @tryAgain="startCallingCircle" />
    <Delay v-if="showServiceDelayAlert" @handelCancel="actShowModalCancelCall"
           @handelConfirm="onClickContinueDelayAlert" />
    <CancelCall v-if="showModalCancelCall" @handelCancel="hideCancelModal" @handelConfirm="actCancelCall" />
  </section>
</template>
<script>
import PlButton from '@/components/button/Button';
import Chat from '@/packages/@video-call/components/chat/Chat';
import VideoCallMixin from '@/packages/@video-call/mixins/VideoCallMixin';
import SearchingProfessional from '@/packages/@video-call/components/searching-professional/SearchingProfessional.vue';
import LocalVideo from '@/packages/@video-call/components/local-video/LocalVideo.vue';
import VideoActions from '@/packages/@video-call/components/video-actions/VideoActions.vue';
import RemoteVideo from '@/packages/@video-call/components/remote-video/RemoteVideo.vue';
import ConnectionIndicator from '@/packages/@video-call/components/connection-indicator/ConnectionIndicator.vue';
import CancelCall from '@/packages/@video-call/components/modals/cancel-call/CancelCall.vue';
import CallTimeout from '@/packages/@video-call/components/modals/timeout/CallTimeout.vue';
import Delay from '@/packages/@video-call/components/modals/delay/Delay.vue';
import InfoAlert from '@/packages/@video-call/components/modals/alert/InfoAlert.vue';
import RemoteStreamMediaStatus
  from '@/packages/@video-call/components/remote-stream-media-status/RemoteStreamMediaStatus.vue';
import ChatButton from '@/packages/@video-call/components/chat-button/ChatButton.vue';
import ChatModal from '@/packages/@video-call/components/chat-modal/ChatModal.vue';
import ChatMixin from '@/packages/@video-call/mixins/ChatMixin';
import NoPermissionAlert from '@/packages/@video-call/components/no-permissions-alert/NoPermissionAlert.vue';

export default {
  name: 'PortalPatientVideo',
  mixins: [VideoCallMixin, ChatMixin],
  components: {
    NoPermissionAlert,
    ChatModal,
    ChatButton,
    RemoteStreamMediaStatus,
    InfoAlert, Delay, CallTimeout, CancelCall,
    ConnectionIndicator, RemoteVideo, VideoActions, LocalVideo,
    SearchingProfessional,
    PlButton,
    Chat
  },

  data() {
    return {
      isMobile: true,
    };
  },
  methods: {
    consoleLog(...params) {
      if (window?.ReactNativeWebView) {
        return window?.ReactNativeWebView?.postMessage(
          JSON.stringify({ type: 'CONSOLE', logType: 'LOG', params })
        );
      }
    },
    consoleInfo(...params) {
      if (window?.ReactNativeWebView) {
        return window?.ReactNativeWebView?.postMessage(
          JSON.stringify({ type: 'CONSOLE', logType: 'INFO', params })
        );
      }
    },
    consoleWarn(...params) {
      if (window?.ReactNativeWebView) {
        return window?.ReactNativeWebView?.postMessage(
          JSON.stringify({ type: 'CONSOLE', logType: 'WARN', params })
        );
      }
    },
    consoleError(...params) {
      console.error(...params);
      if (window?.ReactNativeWebView) {
        return window?.ReactNativeWebView?.postMessage(
          JSON.stringify({ type: 'CONSOLE', logType: 'ERROR', params })
        );
      }
    },
    hideCancelModal() {
      this.showModalCancelCall = false;
    },
    getNpsRoute() {
      return 'mobile-nps';
    }
  }
};
</script>
