import Firebase from "@/helpers/firebase/index";

class FirebaseService {
  constructor() {
    console.log('CONSTRUCT FirebaseService',Firebase)
    Firebase.setFirebaseConfig()
    Firebase.setFirestoreConfig()
  }

  // * LISTENERS
  listenerRoomLinkAgoraByPatientId (patientId, callback) {
    return Firebase.listenerRoomLinkAgora(patientId, async (payload) => {
      callback(payload)
    });
  }

  listenerRoomSpecificLinkAgora (channelName, callback) {
    return Firebase.listenerRoomSpecificLinkAgora(channelName, async (payload) => {
      callback(payload)
    });
  }

  listenerByPatientId (patientId, callback) {
    return Firebase.onListenerByPacId(patientId, async (payload) => {
      callback(payload)
    });
  }

  // * ACTS
  async updatePatientSpecificKey (patientId, key, value) {
    await Firebase.updatePatientSpecificKey(patientId, key, value)
  }

  async getLinkAgoraPAByPatientId (patientId) {
    const room = await Firebase.getLinkAgoraPAByPatientId(patientId)
    return room
  }

  async removeRoomByCanal(channelName){
    await Firebase.removeRoomByCanal(channelName)
  }

  async checkLockedRoom (channelName) {
    const rooms = await Firebase.verifyBlockedRoom(channelName)
    return rooms;
  }
  async checkUnlockedRoom (patientId) {
    const rooms = await Firebase.getAllLinkAgoraByPatientId(patientId)
    if (!room.bloqueado) {
      return true
    }
    const unlockedRoom = rooms.some(room => room.bloqueado === 0)
    return unlockedRoom
  }

  async updateRoomSpecificKey (channel, key, value) {
    await Firebase.updateRoomSpecificKey(channel, key, value)
  }
  async unblockRoom (channel) {
    await Promise.all([
      Firebase.updateRoomSpecificKey(channel, 'bloqueado', 0),
      Firebase.updateRoomSpecificKey(channel, 'patientCancel', false),
    ])
  }

  async updateStreamIdPacienteByRoom (channel, streamId) {
    await Firebase.updateStreamIdPacienteByRoom(channel, streamId)
  }

  async isThereStreamIdPacienteByPatientId(patientId){
    const response = await Firebase.isThereStreamIdPacienteByPatientId(patientId);
    return response
  }
}

export default FirebaseService
