import { render, staticRenderFns } from "./PatientNps.vue?vue&type=template&id=6457c2ff&scoped=true"
import script from "./PatientNps.vue?vue&type=script&lang=js"
export * from "./PatientNps.vue?vue&type=script&lang=js"
import style0 from "./PatientNps.scss?vue&type=style&index=0&id=6457c2ff&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6457c2ff",
  null
  
)

export default component.exports