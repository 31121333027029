<style src="./VideoActions.scss" scoped lang="scss">

</style>
<template>
  <section class="video__actions">
    <slot />
    <BlurButton class="va_btn" :class="{ red: videoOn === false }" title="Vídeo" @click="onClickVideoOnOff">
      <i class="fas fa-video" v-if="videoOn === true" />
      <i class="fas fa-video-slash" v-if="videoOn === false" />
    </BlurButton>
    <button class="cancel-button" @click="$emit('cancelCall')">
      <svg width="39" height="16" viewBox="0 0 39 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M38.0111 11.1128L37.5936 13.0323C37.2012 14.8318 35.4793 16.0022 33.5707 15.7677L29.7689 15.2995C28.114 15.095 26.7302 13.8784 26.3486 12.2927L25.2885 7.87568C23.5664 7.11634 21.6568 6.74074 19.5608 6.74736C17.5422 6.73746 15.5422 7.13267 13.6792 7.90961L12.8448 11.9979C12.5276 13.5456 11.2118 14.6734 9.58262 14.7929L5.78484 15.076C3.88875 15.2162 2.10886 13.9657 1.61871 12.1507L1.09267 10.2065C0.570061 8.27032 1.13397 6.26068 2.57463 4.93394C5.97575 1.79746 11.5446 0.328192 19.2848 0.520109C27.0366 0.712724 32.697 2.46882 36.2592 5.78642C37.7585 7.18212 38.4261 9.20802 38.0111 11.1128Z" fill="white"/>
      </svg>
    </button>
    <BlurButton class="va_btn" :class="{ red: audioOn === false }" title="Microfone" @click="onClickAudioOnOff">
      <i class="fas fa-microphone" v-if="audioOn === true" />
      <i class="fas fa-microphone-slash" v-if="audioOn === false" />
    </BlurButton>
  </section>
</template>
<script>
import moment from 'moment/moment';
import MovementLogs from '@/helpers/movement_logs';
import AgoraService, { AGORA_SERVICE_CUSTOM_EVENTS } from '@/packages/@video-call/services/AgoraService';
import AttendancePreferences from '@/services/attendance-preferences/AttendancePreferences';
import BlurButton from '@/components/blur-button/BlurButton';

export default {
  name: 'VideoActions',
  components: {
    BlurButton,
  },
  props: {
    agoraService: {
      required: true,
      type: AgoraService
    }
  },
  data() {
    return {
      videoOn: true,
      audioOn: true
    };
  },
  mounted() {
    this.agoraService.on(AGORA_SERVICE_CUSTOM_EVENTS.FINISHED_ENTER_ROOM, this.onfinishEnterRoom);
  },
  methods: {
    onfinishEnterRoom() {
      try {
        const preferences = AttendancePreferences.getPreferences();
        if(!preferences) {
          return this.$toast.info('Preferencias de áudio/vídeo não encontradas');
        }
        if (!preferences.audioEnable) {
          this.onClickAudioOnOff();
        }
        if (!preferences.videoEnable) {
          this.onClickVideoOnOff();
        }
        AttendancePreferences.cleanPreferences()
      } catch (error) {
        console.error(error);
        this.$toast.error('Falha ao definir preferências de áudio/vídeo.');
      }
    },
    async onClickVideoOnOff() {
      try {
        const isOn = await this.agoraService.toggleVideo();
        this.videoOn = isOn;
        const objLog = { ...this.userLogin };
        const timeAction = moment().format('YYYY-MM-DD HH:mm:ss');
        const code = isOn ? 1702 : 1703;
        MovementLogs.saveLog(code, timeAction, objLog);
      } catch (e) {
        console.error('Erro onClickVideoOnOff', e);
        this.$toast.error('Falha o ativar/desativar vídeo.');
      }
    },
    async onClickAudioOnOff() {
      try {
        const isOn = await this.agoraService.toggleAudio();
        this.audioOn = isOn;
        const objLog = { ...this.userLogin };
        const timeAction = moment().format('YYYY-MM-DD HH:mm:ss');
        const code = isOn ? 1704 : 1705;
        MovementLogs.saveLog(code, timeAction, objLog);
      } catch (e) {
        console.error('Erro onClickAudioOnOff', e);
        this.$toast.error('Falha o ativar/desativar audio.');
      }
    }
    // onClickBlurOnOff() {
    //   this.agoraService.blurOnOff((response) => {
    //     if (response.status) {
    //       this.blurOn = false;
    //     } else {
    //       this.blurOn = true;
    //     }
    //   });
    // },
  }
};
</script>
