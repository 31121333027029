<style src="./PermissionsCheck.scss" scoped lang="scss">
</style>
<template>
  <section class="permissions-check">
    <PatientHeader v-if="!isWebview" />
    <main class="permissions-check__main">
      <Loading v-show="loading" />
      <span v-if="permissionsAllowed" class="permissions-check__main__description">
        <strong>Verifique se a câmera e o microfone estão funcionando.</strong> Fale no microfone e ouça o retorno para garantir que o áudio está certo.
      </span>
      <NoPermissionsAlert v-if="hasNoPermission" @askPermissions="checkCamera" />
      <div class="permissions-check__main__video-container" v-show="!loading">
        <NoPermissions v-if="hasNoPermission" />
        <video
          v-show="hasPermissions"
          ref="video"
          class="permissions-check__main__video-container__video"
          playsinline
          autoplay
        ></video>
        <PermissionsVideoActions :stream="stream" :permissionsAllowed="permissionsAllowed" />
      </div>
    </main>
    <footer v-show="!loading" class="permissions-check__footer">
      <Button rounded primary arrow-left @click="handleGoBack"></Button>
      <Button check :disabled="!hasPermissions" primary label="Continuar" @click="handleContinue"></Button>
    </footer>
  </section>
</template>

<script>
import PatientHeader from '@/views/paciente/components/header/PatientHeader.vue';
import Button from '@/components/button/Button.vue';
import PermissionsVideoActions
  from '@/views/paciente/permissions-check/components/permissions-video-actions/PermissionsVideoActions.vue';
import Loading from '@/views/paciente/components/loading/Loading.vue';
import NoPermissions from '@/views/paciente/permissions-check/components/permissions-video-actions/NoPermissions.vue';
import NoPermissionsAlert
  from '@/views/paciente/permissions-check/components/permissions-video-actions/NoPermissionsAlert.vue';
import CustomSessionStorage, { STORAGE_KEYS } from '@/services/CustomSessionStorage';

export default {
  components: { NoPermissionsAlert, NoPermissions, Loading, PermissionsVideoActions, Button, PatientHeader },
  data() {
    return {
      permissionsAllowed: false,
      stream: null,
      loading: true
    };
  },
  mounted() {
    this.checkCamera();
  },
  computed: {
    isWebview() {
      return window?.ReactNativeWebView;
    },
    hasPermissions() {
      return !this.loading && this.permissionsAllowed;
    },
    hasNoPermission() {
      return !this.loading && !this.permissionsAllowed;
    }
  },
  methods: {
    async checkCamera() {
      try {
        this.loading = true;
        const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        this.permissionsAllowed = true;
        this.stream = stream;
        this.$refs.video.srcObject = stream;
      } catch (err) {
        this.permissionsAllowed = false;
        this.$toast.error('Não foi possível acessar os dispositivos de áudio e vídeo, verifique as permissões e tente novamente.');
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    async handleContinue() {
      try {
        const redirectPath = this.$route.query.redirect || '/';
        const path = redirectPath + '&permissions-granted=true';
        this.closeStream();
        CustomSessionStorage.remove(STORAGE_KEYS.REFRESH_CALL);
        await this.$router.push(path);
      } catch (error) {
        console.log(error);
      }
    },
    async handleGoBack() {
      await this.$router.go(-2)
    },
    closeStream() {
      if (!this.stream) {
        return;
      }
      this.stream.getTracks().forEach((track) => track.stop());
    }
  }
};
</script>
