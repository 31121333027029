<style scoped lang="scss">
.permissions-check__main__video-container__not_allowed{
  display: flex;
  flex-direction: column;
  flex:1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 0.2rem;
  border-radius: 0.5rem;

  main{
    display: flex;
    flex:1;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0.5rem;
    border-radius: 0.5rem;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  h3{
    text-align: center;
    font-weight: 600;
    line-height: 28.13px;
    color:#fff;
  }
  .permissions-check__main__video-container__not_allowed__icons{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  }
}
</style>

<template>
  <section class="permissions-check__main__video-container__not_allowed" >
    <main>
    <div class="permissions-check__main__video-container__not_allowed__icons">
      <svg width="157" height="128" viewBox="0 0 157 128" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M125.1 111.938V78.3469L150.229 103.562L154.5 107.847V101.797V26.2031V20.1531L150.229 24.4384L125.1 49.6531V16.0625C125.1 12.4089 123.705 9.20447 121.063 6.55399C118.422 3.90344 115.224 2.5 111.575 2.5H16.025C12.3758 2.5 9.1783 3.90344 6.53673 6.55399C3.89523 9.20447 2.5 12.4089 2.5 16.0625V111.938C2.5 115.591 3.89524 118.796 6.53673 121.446C9.1783 124.097 12.3758 125.5 16.025 125.5H111.575C115.224 125.5 118.422 124.097 121.063 121.446C123.705 118.796 125.1 115.591 125.1 111.938Z" stroke="white" stroke-width="5"/>
      </svg>
    </div>
    <h3>As permissões de vídeo não foram liberadas.</h3>
    </main>
  </section>
</template>
<script>
export default {
  name: 'NoPermissions'
};
</script>

