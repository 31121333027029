import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    patient: null,
    patientFromHash: null
  },
  getters: {
  },
  mutations: {
    setPatient(state, obj) {
      state.patient = obj
    },
    setPatientFromHash(state, payload) {
      state.patientFromHash = { ...payload }
    },
    resetPatientFromHash(state) {
      state.patientFromHash = null
    }
  },
  actions: {
    updatePatient({ commit }, payload) {
      commit('setPatient', payload)
    },
    async setLocalStoragePatient({}, payload) {
      // Obter o valor atual do sessionStorage
      const currentPatient = sessionStorage.getItem('patient');
      let updatedPatient = {};
      // Fazer o merge do payload com os campos existentes
      if (currentPatient) {
        updatedPatient = {
          ...JSON.parse(currentPatient), // Campos existentes
          ...payload,                   // Campos do novo payload
          usu_imagem: '',               // Sobrescrever ou adicionar este campo
        };
      } else {
        // Caso não exista nada no sessionStorage, criar um novo objeto
        updatedPatient = {
          ...payload,
          usu_imagem: '',
        };
      }
      // Salvar o objeto atualizado no sessionStorage
      await sessionStorage.setItem('patient', JSON.stringify(updatedPatient));
    },
    getLocalStoragePatient() {
      const storagePatient = sessionStorage.getItem('patient')
      if (!storagePatient) {
        return null
      }
      return JSON.parse(storagePatient)
    },
    removeLocalStoragePatient() {
      sessionStorage.removeItem('patient')
    },
    setPatientToVideoUrl({commit}, payload) {
      commit('setPatientFromHash', payload)
    },
    getPatientToVideoUrl({state}) {
      return state.patientFromHash
    },
    resetPatientToVideoUrl({commit}) {
      commit('resetPatientFromHash')
    }
  }
})
