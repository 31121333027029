<style scoped lang="scss">
.video-local {
  position: fixed;
  display: flex;
  width: 10rem;
  height: 10rem;
  margin: 0.5rem 0 0 0.5rem;
  border-radius: 0.5rem;
  background-color: var(--mainColor);
  filter: drop-shadow(5px 5px 15px rgba(0, 0, 0, 0.27));
  transition: all 0.5s ease;

  .local_stream {
    display: flex;
    flex: 1;
    border-radius: 0.5rem;
    transition: all 0.5s ease;

    div {
      border-radius: 0.5rem;
    }
  }
  &:deep(div) {
    border-radius: 0.5rem;
  }
}
</style>
<template>
  <section class="video-local">
    <div class="local_stream" id="local_stream"></div>
  </section>
</template>

<script>
export default {
  name: 'LocalVideo'
};
</script>
