<style scoped lang="scss">
.video-actions {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  position: absolute;
  z-index: 2;
  bottom: 1.5rem;

  .video-action-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    padding: 4px;
    font-size: 14px;
    border-radius: 100%;
    background-color: #00000085;
    cursor: pointer;
    transition: all 0.5s ease;
    outline: none;
    border: none;
    color: #fff;
    position: relative;

    .icon {
      font-size: 20px;
    }

    &.red {
      background-color: #E23D56;
    }

    &.no-permission {
      &::after {
        content: '!';
        position: absolute;
        top: 7px;
        right: 7px;
        width: 16px;
        height: 16px;
        background: #DB3768;
        border-radius: 100%;
      }
    }
  }
}
</style>
<template>
  <section class="video-actions">
    <BlurButton class="video-action-button" :class="{ red: videoOn === false, 'no-permission':!permissionsAllowed}"
            title="Vídeo" @click="onClickVideoOnOff">
      <i class="fas fa-video icon" v-if="videoOn && permissionsAllowed" />
      <i class="fas fa-video-slash icon" v-if="!videoOn || !permissionsAllowed" />
    </BlurButton>
    <BlurButton class="video-action-button" :class="{ red: audioOn === false, 'no-permission':!permissionsAllowed }"
            title="Microfone"
            @click="onClickAudioOnOff">
      <i class="fas fa-microphone icon" v-if="audioOn && permissionsAllowed" />
      <i class="fas fa-microphone-slash icon" v-if="!audioOn || !permissionsAllowed" />
    </BlurButton>
  </section>
</template>

<script>
import AttendancePreferences from '@/services/attendance-preferences/AttendancePreferences';
import BlurButton from '@/components/blur-button/BlurButton';

export default {
  name: 'PermissionsVideoActions',
  components: {
    BlurButton
  },
  props: {
    stream: MediaStream,
    permissionsAllowed: Boolean
  },
  data() {
    return {
      videoOn: true,
      audioOn: true
    };
  },
  methods: {
    onClickVideoOnOff() {
      if (!this.stream) {
        return this.$toast.error('Não foi possível habilitar/desabilitar vídeo, verifique as permissões', {
          duration: 3000
        });
      }
      const videoTrack = this.stream.getVideoTracks()[0];
      if (!videoTrack) {
        return;
      }
      videoTrack.enabled = !this.videoOn;
      this.videoOn = !this.videoOn;
      AttendancePreferences.savePreferences({ audioEnable: this.audioOn, videoEnable: this.videoOn });
    },
    onClickAudioOnOff() {
      if (!this.stream) {
        return this.$toast.error('Não foi possível habilitar/desabilitar áudio, verifique as permissões', {
          duration: 3000
        });
      }
      const audioTrack = this.stream.getAudioTracks()[0];
      if (!audioTrack) {
        return;
      }
      audioTrack.enabled = !this.audioOn;
      this.audioOn = !this.audioOn;
      AttendancePreferences.savePreferences({ audioEnable: this.audioOn, videoEnable: this.videoOn });
    }

  }
};
</script>
