import FirebaseService from '@/packages/@video-call/services/FirebaseService';
import VideoCallMediator from '@/packages/@video-call/services/VideoCallMediator';

export const VideoCallSelfHealingEvents = {
  INIT: 'init',
  MAXIMUM_ATTEMPTS_EXCEED: 'maximum_tries_exceed'
};

const MAX_ATTEMPTS = 3;
export default class VideoCallSelfHealing {

  constructor(cleanFunction) {
    this._attempts = 1;
    this._videoCallMediator = new VideoCallMediator();
    this._firebaseService = new FirebaseService();
    this._cleanFunction = cleanFunction;
  }

  async tryToHeal({ error, channelName }) {
    if (this._hasExceedMaxTries()) {
      this._videoCallMediator.emit(VideoCallSelfHealingEvents.MAXIMUM_ATTEMPTS_EXCEED, { error });
      return;
    }
    this._videoCallMediator.emit(VideoCallSelfHealingEvents.INIT, {
      attempt: this._attempts
    });
    await this._cleanCall();
    await this._notifyProfessional(channelName);
    this._attempts += 1;
  }

  _hasExceedMaxTries() {
    return this._attempts > MAX_ATTEMPTS;
  }

  async _notifyProfessional(channelName) {
    const key = 'patientError';
    const value = true;
    await this._firebaseService.updateRoomSpecificKey(channelName, key, value);
  }

  async _cleanCall() {
    await this._cleanFunction();
  }

  on(eventName, listener) {
    this._videoCallMediator.on(eventName, listener);
  }
}
