<style src="../BaseModal.scss" scoped lang="scss"></style>
<template>
  <section class="base-modal">
    <section class="base-modal__content">
      <header class="base-modal__header">
        Seu atendimento está próximo
      </header>
      <section class="base-modal__body">
        <p>
          Estamos com um grande número de consultas, seu atendimento ocorrerá
          em alguns minutos.
        </p>
        <p>Agradecemos sua compreensão.</p>
      </section>
      <footer class="base-modal__footer">
        <PlButton class="mt" label="Cancelar" red @click="$emit('handelCancel')" />
        <PlButton class="mt" label="Continuar" blue @click="$emit('handelConfirm')" />
      </footer>
    </section>
  </section>
</template>


<script>
import PlButton from '@/components/button/Button.vue';

export default {
  name: 'Delay' ,
  components: { PlButton }
};
</script>
