export default {
  data(){
    return{
      numMsgChat:null,
      showChat:false
    }
  },
  methods: {
    onClickShowChat() {
      this.showChat = true;
      this.numMsgChat = null;
    },
    onClickHideChat() {
      this.showChat = false;
    },
    onChatMessage({ lastMessage }) {
      if (!lastMessage.isPatient && !this.showChat) {
        this.consoleLog('chegou msg');
        this.playStopAudioAlarm();
        this.numMsgChat += 1;
      }
    },
    playStopAudioAlarm() {
      try {
        const audio = document.getElementById('audio-player-msg');
        this.$nextTick(() => {
          if (audio.paused) {
            audio.play();
          } else {
            audio.pause();
          }
        });
      } catch (e) {
        this.consoleError('Fail on audio player');
      }
    },
  }
}
