import { render, staticRenderFns } from "./PasswordReset.vue?vue&type=template&id=2897dab9&scoped=true"
import script from "./PasswordReset.vue?vue&type=script&lang=js"
export * from "./PasswordReset.vue?vue&type=script&lang=js"
import style0 from "./PasswordReset.scss?vue&type=style&index=0&id=2897dab9&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2897dab9",
  null
  
)

export default component.exports