<style scoped lang="scss">
.no-permissions-alert-container {
  display: flex;
  flex-direction: column;
  background: #00000085;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 3;
  padding: 0.4rem;
  border-radius: 0.4rem;
  color: #FFF;
  align-items: center;
  justify-content: center;
  gap: 4px;

  h1 {
    margin: 0;
    padding: 0;
  }

  .button__permission {
    border-radius: 5px;
    padding: 4px;
    border: 1px solid #DB3768;
    font-weight: bold;
    font-size: 0.8rem;
    color: #FFF;
    background-color: #DB3768;
    cursor: pointer;
    transition: all 0.5s ease;

    &:hover {
      opacity: 0.6;
    }
  }
}
</style>
<template>
  <section class="no-permissions-alert-container" v-show="userHasNotMediaPermission">
    <AlertIcon />
    <h1>Permissões não concedidas.</h1>
    <button
      class="button__permission"
      @click.stop="handleRequestPermissions"
    >
    <span>
      Liberar Permissões
    </span>
    </button>
  </section>
</template>

<script>
import AlertIcon from '@/packages/@video-call/components/no-permissions-alert/AlertIcon.vue';
import MediaDeviceMixin from '@/mixin/MediaDeviceMixin';

export default {
  name: 'NoPermissionAlert',
  mixins: [MediaDeviceMixin],
  components: { AlertIcon },
 async mounted() {
    await this.processPermissions();
  },
  methods: {
    async handleRequestPermissions() {
      if (!navigator?.mediaDevices?.getUserMedia) {
        return this.$toast.error('Atenção, o navegador não suporta câmera e microfone!',{duration:3000});
      }
      let permissaoCameraNegada = false;
      let permissaoMicrofoneNegada = false;
      const resultadoCamera = await navigator.permissions.query({ name: 'camera' });
      if (resultadoCamera.state === 'denied') {
        permissaoCameraNegada = true;
      }
      const resultadoMicrofone = await navigator.permissions.query({ name: 'microphone' });
      if (resultadoMicrofone.state === 'denied') {
        permissaoMicrofoneNegada = true;
      }
      if (permissaoCameraNegada) {
        this.$toast.error(
          'Permissão de câmera negada. Verifique as configurações do navegador e habilite-a para esta página.',
          {
            duration: 5000
          }
        );
      }
      if (permissaoMicrofoneNegada) {
        this.$toast.error(
          'Permissão de microfone negada. Verifique as configurações do navegador e habilite-a para esta página.',
          {
            duration: 5000
          }
        );
      }
      if (permissaoCameraNegada || permissaoMicrofoneNegada) {
        return;
      }
      await this.requestUserMediaPermission();
    }
  }
};
</script>
