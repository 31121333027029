import moment from 'moment/moment';
import MovementLogs from '@/helpers/movement_logs';
import axios from 'axios';
import FirebaseService from '@/packages/@video-call/services/FirebaseService';
import ApiService from '@/packages/@video-call/services/ApiService';
import CallingCircleService from '@/packages/@video-call/services/CallingCircleService';
import AgoraService, {
  AGORA_SERVICE_CUSTOM_EVENTS,
  VideoCallError
} from '@/packages/@video-call/services/AgoraService';
import { ServicoHttp } from '@/axios/servico-http';
import { generateScheduleChannelName } from '@/helpers/generateScheduleChannelName';
import VideoCallSelfHealing, { VideoCallSelfHealingEvents } from '@/packages/@video-call/services/VideoCallSelfHealing';
import CustomSessionStorage, { STORAGE_KEYS } from '@/services/CustomSessionStorage';

const PATIENT_COMMAND = {
  RELOAD_PAGE: 'recarregarPagina',
  FINISH_ATTENDANCE: 'finalizandoAtendimento',
  MEDICAL_FORWARD: 'encaminhamentoMedico',
  SENT_TO_MEDICAL_QUEUE: 'enviadoParaFilaMedica',
  SHOW_GATEWAY_TIMEOUT: 'showGatewayTimeoutMessage',
  ASK_ARM: 'perguntaArm',
  ASK_DOCTOR: 'perguntaMedico',
  CANCEL_CALL: 'cancelaLigacao'
};

export default {
  data() {
    return {
      _apiService: null,
      _firebaseService: null,
      agoraService: null,
      _callingCircleService: null,
      servicoHttp: null,
      //***
      patient: null,
      pacId: null,
      pacCpf: null,
      qsSpaIdVideo: null,
      qsLinkAgenda: null,
      qsAgendamentoPacienteId: null,
      profissionalEntered: false,
      objListenerRoom: null,
      labelCC: null,
      sublabelCC: null,
      // * CTRL de video
      // * ALERTAS
      showAlert: false,
      alertTxt: '',
      showGatewayTimeoutMessage: false,
      showServiceDelayAlert: false,
      // * TRIGGERs
      actRunCLickCancelCall: false,
      enteringRoom: false,
      cmdReloadPage: false,
      attendanceData: {},
      sendAsksEnterRoom: false,
      showVideo: false,
      wasInRequestQueue: false,
      showModalCancelCall: false,
      // * AGORA SDK
      objFun: null,
      listenerRoomLinkAgoraByPatientIdUnsubscribe: null,
      listenerRoomSpecificLinkAgoraUnsubscribe: null,
      listenerByPatientIdUnsubscribe: null
    };
  },
  computed: {
    isAppointment() {
      return this.qsLinkAgenda || this.qsAgendamentoPacienteId;
    },
    isPA() {
      return !this.qsLinkAgenda && !this.qsAgendamentoPacienteId;
    }
  },
  created() {
    this.verifyRefresh();
    window.addEventListener('unload', async (event) => {
      CustomSessionStorage.save(STORAGE_KEYS.REFRESH_CALL, true);
    });
  },
  async beforeDestroy() {
    await this.$store.dispatch('resetPatientToVideoUrl');
    const firebaseUnsubscribers = [
      this.listenerRoomLinkAgoraByPatientIdUnsubscribe,
      this.listenerRoomSpecificLinkAgoraUnsubscribe,
      this.listenerByPatientIdUnsubscribe
    ];
    for (const unsubscribe of firebaseUnsubscribers) {
      if (typeof unsubscribe === 'function') {
        unsubscribe();
      }
    }
    await this.actLeaveRoom();
    window.removeEventListener('unload', async function(event) {
    });
  },
  beforeMount() {
    this._firebaseService = new FirebaseService();
    this._apiService = new ApiService();
    this._callingCircleService = new CallingCircleService();
    this.agoraService = new AgoraService();
    this.servicoHttp = new ServicoHttp(this);
    this._videoCallSelfHealing = new VideoCallSelfHealing(this.actLeaveRoom);
  },
  async mounted() {
    const shouldRefresh = CustomSessionStorage.get(STORAGE_KEYS.REFRESH_CALL);
    if(shouldRefresh) return;
    this.patient = await this.$store.dispatch('getLocalStoragePatient');
    this.pacId = this.patient.pac_id;
    this.pacCpf = this.patient.usu_cpf;
    // * QUERY STRING
    this.qsSpaIdVideo = this.$route.query.video || null;
    this.qsLinkAgenda = this.$route.query.linkAgenda || null;
    this.qsAgendamentoPacienteId = this.$route.query.agendamento || null;
    this._logStartVideoCall();
    await this._setStartCall();
    await this.listenerPatientCommand();
    this.agoraService.on(AGORA_SERVICE_CUSTOM_EVENTS.UNAUTHORIZED_PEAR, () => {
      this.$toast.info(`Uma pessoa não autorizada está tentando entrar na sala.`, {
        duration: 5000
      });
    });
    this._videoCallSelfHealing.on(VideoCallSelfHealingEvents.INIT, ({ attempt }) => {
      this.$toast.info(`Ocorreu um error com sua chamada, vamos reiniciar seu atendimento aguarde um pouco. Tentativa:${attempt}`, { duration: 6000 });
    });
    this._videoCallSelfHealing.on(VideoCallSelfHealingEvents.MAXIMUM_ATTEMPTS_EXCEED, ({ error }) => {
      this.$toast.info('Tentamos reiniciar seu atendimento porém o erro persiste. contate nosso suporte', { duration: 3000 });
      this.$toast.error(error.message);
    });
    const hasRoom = await this._verifyExistentRoomPA();
    if (hasRoom) {
      console.warn('has room?', hasRoom);
      return;
    }
    console.warn('start Attendance');
    this._startAttendance();
  },
  methods: {
     verifyRefresh() {
      const shouldRefresh = CustomSessionStorage.get(STORAGE_KEYS.REFRESH_CALL);
      if (shouldRefresh) {
        const [redirect]= this.$route.fullPath.split('&permissions-granted=true')
        this.$nextTick(()=>{
        CustomSessionStorage.remove(STORAGE_KEYS.REFRESH_CALL);
         this.$router.push({
           path: '/portal-paciente/permissions-check',
           query: { redirect }
        })
        })
      }
    },
    _logStartVideoCall() {
      this.saveMovementsLog(1700);
      this.consoleLog('_logStartVideoCall');
    },
    async _setStartCall() {
      const momBegin = moment(new Date());
      const begin = momBegin.format('YYYY-MM-DD HH:mm:ss');
      await this._firebaseService.updatePatientSpecificKey(this.pacId, 'comecou_ligacao', begin);
    },
    async _verifyExistentRoomPA() {
      if (!this.isPA) {
        return;
      }
      const room = await this._firebaseService.getLinkAgoraPAByPatientId(this.pacId);
      console.warn('room', room);
      if (!room) {
        return null;
      }
      await this.handleListenerRoom();
      return room;
    },

    _startAttendance() {
      if (this.isPA) {
        this.startCallingCircle();
        this.handleListenerRoom();
        return;
      }
      if (this.isAppointment) {
        this.handleListenerRoom();
        return;
      }
      this.consoleError('[PatientVideo]', 'ERROR', 'Não será possível entrar em uma sala');
      this.$toast.error('Não será possível entrar em uma sala', {
        duration: 5000
      });
    },

    async startCallingCircle() {
      this.showGatewayTimeoutMessage = false;
      this.consoleInfo('startCallingCircle');
      const objPatient = this.patient;
      const appVersion = this.appVersion;
      const spaId = this.qsSpaIdVideo;
      const resCCService = await this._callingCircleService.startCallingCircle(objPatient, appVersion, spaId);
      if (!resCCService) {
        this.showGatewayTimeoutMessage = true;
      }
    },

    async actCancelCall(isServiceFinished = false) {
      this.showGatewayTimeoutMessage = false;
      try {
        if (this.actRunCLickCancelCall) {
          return;
        }
        this.actRunCLickCancelCall = true;

        if (isServiceFinished) {
          this.showHideAlertNoTime(true, 'Seu atendimento foi finalizado.');
        } else {
          this.showHideAlertNoTime(true, 'Sua chamada está sendo cancelada. Por favor, aguarde um momento.');
        }

        await this.actLeaveRoom();
        const patientId = this.patient.pac_id;
        await this._callingCircleService.handleCancelCall(patientId, isServiceFinished);
        this.saveMovementsLog(1701);

        if (this.objListenerRoom && !isServiceFinished) {
          const channel = this.objListenerRoom.canal;
          let key = 'autorizacaoEntrar';
          let value = null;
          await this._firebaseService.updateRoomSpecificKey(channel, key, value);
          // caso pleni/pleni#1464
          // this._firebaseService.updateStreamIdPacienteByRoom(channel, value) //
          key = `bloqueado`;
          value = 1;
          await this._firebaseService.updateRoomSpecificKey(channel, key, value);
          await this._firebaseService.updateRoomSpecificKey(channel, 'patientCancel', true);
        }

        await this.showHideAlertNoTime(false, null);
        await this.gotoNps();
      } catch (error) {
        this.consoleError('[PatientVIdeo] actShowModalCancelCall. Error', error);
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 504) {
            this.showHideAlertNoTime(false, null);
            this.showGatewayTimeoutMessage = true;
            return;
          }
        }
        this.showHideAlert(true, 'O cancelamento da sua chamada falhou. Por favor, tente novamente ou entre em contato com o suporte.');
      } finally {
        this.actRunCLickCancelCall = false;
      }
    },

    // * FIREBASE
    async handleListenerRoom() {
      if (this.isAppointment) {
        const channelName = this.qsLinkAgenda ? this.qsLinkAgenda : await this._getScheduleChannelNameByAttendanceId();
        const isRoomLocked = await this._firebaseService.checkLockedRoom(channelName);
        if (isRoomLocked) {
          await this._firebaseService.unblockRoom(channelName);
        }
        await this.listenerRoomSpecificLinkAgora(channelName);
        return;
      }
      // ! por Pronto Atendimento
      this.listenerRoomLinkAgoraByPatientIdUnsubscribe = this._firebaseService.listenerRoomLinkAgoraByPatientId(this.pacId, async (payload) => {
        if (payload === null) {
          return;
        }
        if (!payload.canal.match('PA-funId_')) {
          return;
        }
        if (payload.patientQuit) {
          await this._firebaseService.unblockRoom(payload.canal);
        }
        await this.controllerPayloadListenerRoom(payload);
      });
    },

    async _getScheduleChannelNameByAttendanceId() {
      try {
        const resAgp = await this._apiService.sGetSchedulePatientById.get({
          agpId: this.qsAgendamentoPacienteId
        });
        if (!resAgp.status) {
          throw new Error('GetScheduleByPatient Error');
        }
        const objAgp = resAgp.data;
        return generateScheduleChannelName({
          patientCpf: this.pacCpf,
          professionalId: objAgp.agp_id_funcionarios,
          scheduleDate: objAgp.agp_data,
          scheduleStartTime: objAgp.agp_horario_inicio
        });
      } catch (e) {
        this.$toast.error('Falha ao obter nome do canal de comunicação.');
        this.consoleError('Error on get schedule channel name: ', e);
      }
    },

    async listenerRoomSpecificLinkAgora(channelName) {
      this.objListenerRoom = channelName;
      this.listenerRoomSpecificLinkAgoraUnsubscribe = this._firebaseService.listenerRoomSpecificLinkAgora(channelName, async (payload) => {
        await this.controllerPayloadListenerRoom(payload);
      });
    },

    async controllerPayloadListenerRoom(payload) {
      if (!payload) { // ! Sem payload. Sair da sala
        await this.roomLinkAgoraPayloadNull();
        return;
      }
      if (payload.changeType === 'modified') { // ! Sala modificada.
        await this.roomLinkAgoraPayloadModified(payload);
        return;
      }
      if (payload.changeType === 'added') { // ! Sala criada. Perguntar se pode entrar
        await this.roomLinkAgoraPayloadAdded(payload);
        return;
      }
      if (payload.changeType === 'removed') { // ! Sala destruida. Sair da sala
        await this.roomLinkAgoraPayloadRemoved(payload);
        return;
      }
      // ! Nenhuma opcao anterior
      this.consoleError('[listenerRoomLinkAgora]', 'Nao entrou em nenhum IF');
    },

    async roomLinkAgoraPayloadNull() {
      this.objListenerRoom = null;
      await this.actLeaveRoom();
      this.sendAsksEnterRoom = false;
      this.enteringRoom = false;
      this.cmdForward = false;
    },

    async roomLinkAgoraPayloadModified(payload) {
      this.objListenerRoom = payload;
      if (payload.autorizacaoEntrar === 'respostaSim') { // ! autorizacao para entrar na sala
        const channel = payload.canal;
        const key = 'autorizacaoEntrar';
        const value = null;
        await this._firebaseService.updateRoomSpecificKey(channel, key, value);
        this.enteringRoom = true;
        this.objFun = {
          fun_id: payload.funId,
          fun_nome: payload.funNome
        };
        this.labelCC = ``;
        this.sublabelCC = ``;
        await this.actLeaveRoom();
        await this.actEnterRoom(payload);
        return;
      }
      // ! perguntar se pode entrar na sala
      this.objListenerRoom = payload;

      if (!this.sendAsksEnterRoom) {
        this.sendAsksEnterRoom = true;
        const channel = payload.canal;
        const key = 'autorizacaoEntrar';
        const value = 'pergunta';
        await this._firebaseService.updateRoomSpecificKey(channel, key, value);
      }
      this.labelCC = `Profissional ${payload.funNome} estará disponível para atendê-lo em breve.`;
      this.sublabelCC = ``;
      this.startTimeoutNotifyProfessionalDelay();
    },

    async roomLinkAgoraPayloadAdded(payload) {
      this.objListenerRoom = payload;
      if (payload.bloqueado) {
        return;
      }
      this.sendAsksEnterRoom = true;
      const channel = payload.canal;
      const key = 'autorizacaoEntrar';
      const value = 'pergunta';
      await this._firebaseService.updateRoomSpecificKey(channel, key, value);
      this.labelCC = `Profissional ${payload.funNome} estará disponível para atendê-lo em breve.`;
      this.sublabelCC = ``;
      this.startTimeoutNotifyProfessionalDelay();
    },

    async roomLinkAgoraPayloadRemoved(payload) {
      this.objListenerRoom = null;
      await this.actLeaveRoom();
      this.sendAsksEnterRoom = false;
      this.enteringRoom = false;
      this.cmdForward = false;
    },

    async listenerPatientCommand() {
      this.listenerByPatientIdUnsubscribe = this._firebaseService.listenerByPatientId(this.pacId, (snap) => {
        // ! fila requisicao
        this.labelCC = ``;
        this.sublabelCC = ``;
        if (this.wasInRequestQueue) {
          this.labelCC = `Em breve você será atendido`;
        }
        if (snap.data.filaRequisicaoProntoAtendimento !== 0) {
          this.labelCC = `Você está na fila.`;
          this.sublabelCC = `Seu número é ${snap.data.filaRequisicaoProntoAtendimento}`;
          this.wasInRequestQueue = true;
          const objLog = { ...this.patient, fila: `${this.labelCC}. ${this.sublabelCC}` };
          this.saveMovementsLog(1714, objLog);
        }
        // ! se nao for modificacao, faz nada
        if (snap.type !== 'modified') {
          return;
        }
        this.listenerPatientCommandModified(snap);
      });
    },

    async listenerPatientCommandModified(payload) {
      const data = payload.data;
      if (data.comando === 'nenhum' || data.comando === null) {
      }
      if (data.comando === PATIENT_COMMAND.RELOAD_PAGE) {
        if (this.cmdReloadPage) {
          return;
        }
        window.location.reload();
        this.cmdReloadPage = true;
      }
      if (data.comando === PATIENT_COMMAND.FINISH_ATTENDANCE) {
        console.warn('agoraRoomName', data.agoraRoomName);
        console.warn('channel', this.agoraService.getChannelName());
        if (this.agoraService.getChannelName() && data.agoraRoomName !== this.agoraService.getChannelName()) {
          return;
        }
        this.profissionalEntered = false;
        this.enteringRoom = false;
        const isServiceFinished = true;
        await this.actCancelCall(isServiceFinished);
        this.sendAsksEnterRoom = false;
      }
      if (data.comando === PATIENT_COMMAND.MEDICAL_FORWARD) {
        if (this.cmdForward) {
          return;
        }
        this.showHideAlert(true, 'Você foi encaminhado para um médico. Por favor, aguarde o seu atendimento.');
        this.enteringRoom = false;
        this.sendAsksEnterRoom = false;
        this.cmdForward = true;
        this.profissionalEntered = false;
        const patientId = this.pacId;
        const key = 'comando';
        const value = null;
        this._firebaseService.updatePatientSpecificKey(patientId, key, value);
        this.startTimeoutNotifyProfessionalDelay();
        this.actLeaveRoom();
      }
      // ! comando - enviado para fila medica
      if (data.comando === PATIENT_COMMAND.SENT_TO_MEDICAL_QUEUE) {
        if (this.cmdSendToDoctorQueue) {
          return;
        }
        this.profissionalEntered = false;
        this.startTimeoutNotifyProfessionalDelay();
        this.cmdSendToDoctorQueue = true;
      }
      // ! comando - exibir mensagem de timeout
      if (data.comando === PATIENT_COMMAND.SHOW_GATEWAY_TIMEOUT) {
        if (this.cmdShowGatewayTimeoutMessage) {
          return;
        }
        this.showGatewayTimeoutMessage = true;
        this.cmdShowGatewayTimeoutMessage = false;
        const patientId = this.pacId;
        const key = 'comando';
        const value = null;
        this._firebaseService.updatePatientSpecificKey(patientId, key, value);
      }
      // ! comando - pergunta / resposta
      if (data.comando === PATIENT_COMMAND.ASK_ARM || data.comando === PATIENT_COMMAND.ASK_DOCTOR) {
        if (this.cmdAskArmOrDoctor) {
          return;
        }
        this.cmdAskArmOrDoctor = false;
        const patientId = this.pacId;
        const key = 'comando';
        const value = 'resposta';
        this._firebaseService.updatePatientSpecificKey(patientId, key, value);
      }
      // ! comando - cancelar ligacao
      if (data.comando === PATIENT_COMMAND.CANCEL_CALL) {
        if (this.cmdCancelCall) {
          return;
        }
        this.onClickCancelCall();
        this.cmdCancelCall = true;
      }
    },

    async actEnterRoom(payload) {
      try {
        this.showVideo = true;
        await this.agoraService.enterRoom(payload, this.patient);
        this.profissionalEntered = true;

      } catch (error) {
        await this._videoCallSelfHealing.tryToHeal({ error, channelName: payload.canal });
        if (error instanceof VideoCallError) {
          return this.$toast.error(error.message, { duration: 3000 });
        }
        return this.$toast.error('Erro inesperado ao entrar na sala de atendimento.', { duration: 3000 });
      }
    },
    async actLeaveRoom() {
      await this.agoraService.leaveRoom();
    },
    // * ALERTAS
    async showHideAlertNoTime(status, txt) {
      this.alertTxt = txt;
      this.showAlert = status;
    },
    async showHideAlert(status, txt, gotoNps = false) {
      this.alertTxt = txt;
      this.showAlert = status;
      setTimeout(async () => {
        this.showAlert = false;
        this.alertTxt = null;
        if (gotoNps) {
          // this.$router.push({ name: "pacienteHome" });
          await this.gotoNps();
        }
      }, 8 * 1000);
    },
    // * GOTO
    async gotoNps() {
      const response = await this.servicoHttp.get({
        url: 'api/pep/atendimento/obter-ultimo-atendimento',
        params: { pacId: this.pacId }
      });
      if (response.data.status) {
        this.attendanceData = { ...response.data.data };
      }
      if (this.attendanceData?.atd_id) {
        const params = {
          name: this.getNpsRoute(),
          query: {
            video: this.attendanceData?.atd_id,
            from: this.qsSystemFrom
          }
        };
        return this.$router.push(params);
      }
      return this.$router.push({ name: 'pacienteHome' });
    },
    // * VALIDACOES
    // * MODAL DELAY
    startTimeoutNotifyProfessionalDelay() {
      if (this.profissionalEntered || this.showServiceDelayAlert) {
        return;
      }
      this.showServiceDelayAlert = false;
      const seg = 60;
      const min = 5;
      setInterval(() => {
        if (this.profissionalEntered || this.showServiceDelayAlert) {
          return;
        }
        this.showServiceDelayAlert = true;
      }, min * seg * 1000);
    },
    actShowModalCancelCall() {
      this.showModalCancelCall = true;
    },
    async onClickContinueDelayAlert() {
      this.showServiceDelayAlert = false;
      this.startTimeoutNotifyProfessionalDelay();
    },
    saveMovementsLog(code, input) {
      const objLog = input ? input : { ...this.patient };
      const timeAction = moment().format('YYYY-MM-DD HH:mm:ss');
      MovementLogs.saveLog(code, timeAction, objLog);
    }
  }
};
