<template>
  <svg width="32" height="32" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M10.5 7.875V12.25" stroke="#DB3768" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M10.5 18.7338H5.1975C2.16125 18.7338 0.892502 16.5638 2.3625 13.9125L5.09251 8.99503L7.66501 4.37506C9.22255 1.56631 11.7775 1.56631 13.335 4.37506L15.9075 9.00378L18.6375 13.9213C20.1075 16.5725 18.83 18.7425 15.8025 18.7425H10.5V18.7338Z" stroke="#DB3768" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M10.4951 14.875H10.5034" stroke="#DB3768" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "AlertIcon",
  components: {},

  data: () => {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>
