<style src="../BaseModal.scss" scoped lang="scss"></style>
<template>
  <section class="base-modal">
    <section class="base-modal__content">
      <header class="base-modal__header">Cancelar Ligação</header>
      <section class="base-modal__body">
        <p>Tem certeza de que deseja cancelar a ligação?</p>
      </section>
      <footer class="base-modal__footer">
        <PlButton class="mt" label="Não" gray @click="$emit('handelCancel')" />
        <PlButton class="mt" label="SIM" red @click="$emit('handelConfirm')" />
      </footer>
    </section>
  </section>
</template>
<script>
import PlButton from '@/components/button/Button.vue';

export default {
  name: 'CancelCall',
  components: { PlButton }
};
</script>
