<style scoped lang="scss">
.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #00000085;
  height: 40px;
  border-radius: 36px;
  padding: 10px 16px;
  gap: 8px;
  position: absolute;
  bottom: 6rem;
  right: 50%;
  left: 50%;
  z-index: 3;
  transform: translate(-50%, -50%);
  width: 15rem;
  align-self: center;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.06px;
  text-align: center;

  .fas {
    font-size: 16px;
  }
}
</style>

<template>
  <section class="container" v-show="muteAudio || muteVideo">
    <i class="fas fa-microphone-slash" v-show="muteAudio" />
    <i class="fas fa-video-slash" v-show="muteVideo" />
    <span>O profissional está com {{ label }}</span>
  </section>
</template>

<script>
import AgoraService, { AGORA_SERVICE_EVENTS } from '@/packages/@video-call/services/AgoraService';

export default {
  name: 'RemoteStreamMediaStatus',
  props: {
    agoraService: AgoraService
  },
  data() {
    return {
      muteAudio: false,
      muteVideo: false
    };
  },
  computed: {
    label() {
      if (!this.muteAudio && !this.muteVideo) {
        return '';
      }
      if (this.muteAudio && this.muteVideo) {
        return 'microfone e vídeo desabilitados.';
      }
      if (this.muteVideo) {
        return 'vídeo desabilitado.';
      }
      return 'microfone desabilitado.';
    }
  },
  mounted() {
    this.agoraService.on(AGORA_SERVICE_EVENTS.INFO_UPDATE, (input) => {
      const [_, action] = input;
      const audioActions = {
        'mute-audio': true,
        'unmute-audio': false
      };
      const videoActions = {
        'mute-video': true,
        'unmute-video': false
      };
      this.muteVideo = videoActions[action] !== undefined ? videoActions[action] : this.muteVideo;
      this.muteAudio = audioActions[action] !== undefined ? audioActions[action] : this.muteAudio;
    });
    this.agoraService.on(AGORA_SERVICE_EVENTS.USER_LEFT, (input) => {
      const [_,status] = input;
      if(status === 'Quit'){
        this.muteAudio = false;
        this.muteVideo = false;
      }
    })
  }
};
</script>
